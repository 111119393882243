<template>
    <div>
        <relationPlayerList :game_id="game_id"></relationPlayerList>
    </div>
</template>
<script>

import relationPlayerList from '@/components/relation/games/player/list.vue';

export default {
    components: {
        relationPlayerList
    },
    data () {
        let self = (window.x = this);
        return {
            game_id: 1,
        }
    },
    methods: {
        demo () {
        },
        // end 
    },
    mounted () {
        // this.getData()
    },
    watch: {},
    computed: {}
}
</script>
